import React from 'react'
import { WrapperContainer, WrapperSection } from '../../wrappers'
import { Grid } from '@material-ui/core'
import SideLine from '../../elements/SideLine/SideLine'
import { s, colors, globals, alpha } from '../../style'

const AboutStats = ({ data, theme }) => {
  // console.log(data)
  const { title, aboutStats_items, slug_section } = data
  return (
    <WrapperSection
      id={slug_section}
      extraCss={sSection}
      outside={<SideLine theme={theme}>{title}</SideLine>}>
      <WrapperContainer theme={theme}>
        <Grid container css={[sStats, { color: colors[theme].main }]}>
          {aboutStats_items.map(({ desc, icon, value }, id) => (
            <Grid
              xs={12}
              sm={12}
              md={aboutStats_items.length % 2 === 0 ? 6 : 4}
              lg={3}
              item
              css={[sStatsInner]}
              key={id}>
              <div css={{ position: 'relative', textAlign: 'center' }}>
                <img
                  className="stat-icon"
                  src={icon.publicURL}
                  alt={icon.name}
                />
                <div className="stat-value" css={sGradient(theme)}>
                  {value}
                </div>
                <div className="stat-title">{desc}</div>
              </div>
            </Grid>
          ))}
        </Grid>
      </WrapperContainer>
    </WrapperSection>
  )
}

const sSection = {
  contentVisibility: 'auto',
  [s.md]: {
    paddingTop: 0,
  },
  [s.lg]: {
    marginTop: globals.spacing.between.desktop,
    paddingTop: 0,
  },
}

const sStats = {
  padding: `${globals.spacing.inside.mobile * 2}px 0`,
  '.stat-symbol, .stat-value, .stat-unit': {
    position: 'relative',
    fontSize: '4rem',
    fontWeight: 700,
    [s.xs]: { fontSize: '3rem' },
  },
  '.stat-title': {
    position: 'relative',
    fontSize: '1rem',
    fontWeight: 700,
    opacity: 0.6,
    [s.sm_down]: { marginBottom: '2rem' },
  },
  '.stat-icon': {
    width: 200,
    height: 200,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.08,
    [s.sm_down]: {
      width: 96,
      height: 96,
    },
  },
}

const sStatsInner = {
  flexDirection: 'column',
  [s.md_only]: {
    padding: '2rem 0',
  }
}

const sStat = {
  display: 'flex',
  flexDirection: 'column',
  // position: 'relative',
  [s.sm_down]: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: globals.spacing.inside.mobile * 3,
    '&:last-of-type': { paddingBottom: 0 },
  },

  [s.md]: {
    padding: '0 1rem',
  },

  inner: {
    display: 'flex',
    position: 'relative',
  },
}

const sGradient = (theme) => ({
  background: `linear-gradient(45deg, ${colors[theme].mainLighter.concat(
    alpha[100]
  )}, ${colors[theme].mainDarker.concat(alpha[100])})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
})

export default AboutStats
